.mains {
   width: 100%;
   position: relative;
   height: auto;
   position: absolute;
   padding-top: 172px;
   background-color: #F2F2F2;

   opacity: 1;
}

.main {
   background-color: #F2F2F2;


}

.heading {
   color: #222222;
   font: normal normal bold 40px/49px Montserrat;
   letter-spacing: 0px;
   color: #222222;
   text-transform: uppercase;
   opacity: 1;
   padding-bottom: 13px;
}

.footer {
   padding-top: 170px;
}

.specfication {
   padding: 100px;
}

.nav {
   text-decoration: none;
   list-style: none;
}

.nav li {
   text-decoration: none;
   display: inline-block;
   margin: 29px;
   border: 1px solid #CBCBCB;
   border-radius: 8px;
   opacity: 1;
   padding: 14px;
   color: #222222;
   opacity: 1;
   font-size: 14px;
   cursor: pointer;
   font-family: Montserrat;
   font-weight: 600;
}

.active {
   font-family: Montserrat;
   background: #1AAE4E 0% 0% no-repeat padding-box;
   color: white !important;
}

.bgColor {
   // width: 250px;
   // height: 250px;
   color: #222222;
   font-family: Montserrat;
   font-weight: 600;
   background: white;
   border-radius: 8px;
   padding: 75px 0px;
   font-weight: bold;
   border: 1px solid #707070;
}

.bgColor:hover {
   // width: 250px;
   // height: 250px;
   color: white;
   font-family: Montserrat;
   font-weight: 600;
   background: #1AAE4E;
   border-radius: 8px;
   padding: 75px 0 ;
   cursor: pointer;
   // line-height: 200px;
}

.boxtext {
   text-align: center;
   font-size: 14px;

}

.boxtextpdf {
   padding: 8px 0px 0 0;
   font-size: 12px;
   text-align: center;
   // color: white!important;
}

.arrowIcon {
   width: 12px;
   height: 12px;
}

@media only screen and (min-width: 320px) and (max-width : 1024px) {
   
.specfication {
   padding: 0px;
}
   .heading {
      color: #222222;
      font-weight: bold;
      font-size: 24px;
      // font: normal no   rmal bold 40px/49px Montserrat;
      letter-spacing: 0px;
      color: #222222;
      text-transform: uppercase;
      opacity: 1;
      padding-bottom: 25px;
      line-height: 1;
   }


   .footer {
      padding-top: 0px;
   }

   .nav {
      // display: flex;
      margin: 10px;
      padding: 0;
   }

   .nav li {
      margin: 0 2px;
      padding: 8px;
   }
   .bgColor {
      margin-bottom:10px
   }
}