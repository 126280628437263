.main {
   background-color: #F2F2F2;
   height: 698px;
   opacity: 1;
   position: absolute;
   padding-top: 172px;
   width: 100%;

}
.popUp{
   text-align: center;
   display: block;
}
.popuptitle{
   font-size: 18px;
   text-align: center;
   width: 100%;
}
.popupMessage{
   padding: 20px;
   text-align: center;
   width: 100%;
}
.popUpSubmit{
   width: 100%;
   text-align: center;
   display: block;
   border: none;
}
.heading {
   color: #222222;
   font: normal normal bold 40px/49px Montserrat;
   letter-spacing: 0px;
   color: #222222;
   text-transform: uppercase;
   opacity: 1;
   padding-bottom: 63px;
}

.personalInfo {
   height: 788px;
   /* UI Properties */
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 0px 2px 20px #0000000F;
   border-radius: 30px 0px 0px 30px;
   opacity: 1;
   padding: 90px 100px;
}

.perosnal {
   height: 788px;
   /* UI Properties */
   background: #03C03C 0% 0% no-repeat padding-box;
   border-radius: 0px 30px 30px 0px;
   opacity: 1;
   padding: 45px;
 
}

.textlabel {
   // text-align: left;
   font-weight: 600;
   font-size: 14px;
   // font: normal normal 600 14px Montserrat;
   letter-spacing: 0px;
   color: #222222;
   opacity: 1;
}

.coloroptional {
   color: #8F8F8F;
   font-size: 10px;
}

.btnSubmit {
   width: 241px;
   height: 56px;
   /* UI Properties */
   background: #FF8439 0% 0% no-repeat padding-box;
   border-radius: 10px;
   opacity: 1;
   border-width: 0;
   text-align: center;
   font-size: 16px;
   // font: normal normal medium 16px/19px Montserrat;
   letter-spacing: 0px;
   color: #FFFFFF;

}

.customPersonalInfo {
   width: 70px;
   height: 70px;
   background: #FFFFFF;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
}

.peronsalimagewidht {
   width: 24px;

   height: 24px;
}

.personaltextheading {
   font-size: 16px;
   color: #FFFFFF;
   font-weight: 600;
   margin: 0;
   padding: 0;
   // line-height: 0;
}

.paddinghtitle {
   padding: 20px 0px 20px 12px;
   line-height: 1;
}

.personaltextsubheading {
   font-size: 14px;
   letter-spacing: 0px;
   color: #A7F1BD;
   opacity: 1;
   margin: 0;
   padding: 0;
   word-break: break-all   ;
   // line-height: 0;
}
.paddingicon{
   padding: 40px 0px 0px 90px;
}
.footer{
   padding-top: 70px;
}
@media only screen and (min-width: 320px) and (max-width : 1024px)  {
   .personalInfo {
      height: 100%;
      /* UI Properties */
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 20px #0000000F;
      border-radius: 0px;
      opacity: 1;
      padding: 20px;
   }
   .perosnal {
      height: 100%;
      /* UI Properties */
      background: #03C03C 0% 0% no-repeat padding-box;
      border-radius:  0px;
      opacity: 1;
      padding: 20px;
    
   }
   .paddingicon{
      padding: 10px;
   }
   .heading {
      color: #222222;
      font-weight: bold;
      font-size: 24px;
      // font: normal no   rmal bold 40px/49px Montserrat;
      letter-spacing: 0px;
      color: #222222;
      text-transform: uppercase;
      opacity: 1;
      padding-bottom: 25px;
      line-height: 1;
   }
   .footer{
      padding-top: 0px;
   }
   .btnSubmit {
      width: 100%;
      height: 56px;
      /* UI Properties */
      background: #FF8439 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      border-width: 0;
      text-align: center;
      font-size: 16px;
      // font: normal normal medium 16px/19px Montserrat;
      letter-spacing: 0px;
      color: #FFFFFF;
   
   }
}