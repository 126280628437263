body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.firstTextbox{
  color: #ffffff!important;
  width: 300px!important;
  height: 56px!important;
  border-radius: 10px!important;
  background-color: #ffffff10!important;
  margin: 0px!important;
  font-size: 14px;
  font-family: 'Montserrat'!important;
  padding: 0!important;
  border-radius: 10px!important;
  /* background: #ffffff10 url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='15' height='15' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat!important; */
  /* background-position: right 5px top 50%!important; */
}

/* .firstTextbox option{
  color: #000000!important;
  font-size: 20px!important;
}
option:not(:checked) { 
  background-color: #FFF; 
} */