.mains{
   width: 100%;
   position: relative;
   height: auto;
     position: absolute;
     padding-top: 172px;
     background-color: #F2F2F2;

     opacity: 1;
}
.main {
   background-color: #F2F2F2;
   

}
.heading {
   color: #222222;
   font: normal normal bold 40px/49px Montserrat;
   letter-spacing: 0px;
   color: #222222;
   text-transform: uppercase;
   opacity: 1;
   padding-bottom: 13px;
}
.subHeading {
   color: #222222;
   font-size: 20px;
   font-weight: bold;
   letter-spacing: 0px;
   color: #222222;
   text-transform: uppercase;
   opacity: 1;
   padding-bottom: 13px;
   opacity: 0.6;
}
.privacyHeading{
   font-size: 30px;
   font-weight: bold;
   color: #222222;
opacity: 1;
}
.texhSubheading{
   color: #222222;
opacity: 0.6;
font-size: 16px;
padding-bottom: 15px;
}
.footer{
   padding-top: 170px;
}
@media only screen and (min-width: 320px) and (max-width : 1024px)  {
   .heading {
      color: #222222;
      font-weight: bold;
      font-size: 24px;
      // font: normal no   rmal bold 40px/49px Montserrat;
      letter-spacing: 0px;
      color: #222222;
      text-transform: uppercase;
      opacity: 1;
      padding-bottom: 25px;
      line-height: 1;
   }
   .subHeading {
      color: #222222;
      font-weight: bold;
      font-size: 20px;
      // font: normal no   rmal bold 40px/49px Montserrat;
      letter-spacing: 0px;
      color: #222222;
      text-transform: uppercase;
      opacity: 0.6;
      padding-bottom: 25px;
      line-height: 1;
   }
   .footer{
      padding-top: 0px;
   }
   .privacyHeading{
      font-size: 18px;
      text-align: center;
   }
}