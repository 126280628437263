.headingName {
   font-size: 30px;
   color: #222222;
   font-weight: bold;
}

.imgLocation {
   width: 20px;
   height: 20px;
}

.countryName {
   font-size: 14px;
   color: #222222;
   opacity: 0.5;

}

.productImage {
   width: 100%;
   height: auto;
   border-radius: 20px;
   object-fit: cover;
   border:none ;

}

.imageProdutborder {
   width: 100%;
   height: 75px;
   border-radius: 20px;
   margin: 0;
   padding: 0;
   background-size: auto;
   border: 2px solid #222222;
   cursor: pointer;
}
.imageProdutnotborder{
   width: 100%;
   height: 75px;
   border-radius: 20px;
   margin: 0;
   padding: 0;
   background-size: auto;
  cursor: pointer;
}

.columnImgs {
   padding: 0;
   flex: 0 0 auto;
   width: 6.33333333%;
   margin: 0;
}

.multipleImages {
   padding: 20px;
}
.price{
   font-size: 50px;
   color: #03C03C;
   font-weight: bold;
   line-height: 75px;
}

.sold{
   width: 140px;
height: 105px;
margin-left: 70px;
}
.btncallUs{
   line-height: 46px;
   background-color: #FF8439;
   width: fit-content;
   padding: 0px 20px;
   height: 46px;
   color: white !important;
   font-size: 12px;
   text-align: center;
   border-radius: 10px;
   opacity: 1;
   
}
.sound{
   width: 24px;
   height: 24px;
 margin-right: 20px;
}
.productdescription{
   padding-top: 25px;
}
.descriptionHeading{
   color: #222222;
   font-size: 20px;
   font-weight: bold;
   padding-bottom: 20px;
}
.descriptionfeaturesheading{
   color: #222222;
   opacity: 0.6;
   padding-bottom: 15px;
   font-size: 16px;
}
.descriptionfeatures{
   color: #222222;
   opacity: 1;
   padding-bottom: 15px;
   font-size: 14px;
   font-weight: 600;
}
cardHeading {
   font-size: 18px;
   font-weight: 600;
}

.fwnormal {
   font-weight: 400;
   font-size: 14px;
}

.fwBold {
   font-weight: 600;
   font-size: 14px;
}

.zoomWrapper {
   // border:1px dashed #B7B8BD;
   max-width: 400px;
   overflow: hidden;
   margin: 0 auto 40px auto;
   cursor: pointer;
   position: relative;
   /* -- Prevent flicker -- */
   // -webkit-backface-visibility: hidden;
   // -webkit-transform: translate3d(0, 0, 0);
}

.zoomWrapper img {
   max-width: 100%;
   height: auto;
   display: block;
   -webkit-transition: all 1s ease;
   -moz-transition: all 1s ease;
   -ms-transition: all 1s ease;
   -o-transition: all 1s ease;
   transition: all 1s ease;
}

.zoomWrapper img:hover {
   -webkit-transform: scale(1.25);
   -moz-transform: scale(1.25);
   -ms-transform: scale(1.25);
   -o-transform: scale(1.25);
   transform: scale(1.25);
}

.imgHover {
   position: relative;
}

.imageText {
   position: absolute;
}

.topsold {
   position: absolute;
   width: 230px;
   height: 230px;
   // border-radius: 50%;
   // background: red;
   right: 0px;
   top: 0px;
   overflow: hidden;
}
.solds{
   top: 5px;
   color: white;
   transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
   background-color: #03C03C;
   position: absolute;
   width: 230px;
   height: 45px;
   left: 20px;
   font-weight: bold;
   text-align: center;
   font-size: 14px;
   padding: 8px 4px 0px 0px;
  
}
.border {
   border : '2px solid red'
}
.noBorder{
   border : '2px solid transparent'
}
.imgBorder{
   background: #FFFFFF 0% 0% no-repeat padding-box;
   border-radius: 20px;
   border: 2px;
   position: relative;
   border: 1px solid #00000010;
   width: 85%;
}
.topsoldrecent {
   position: absolute;
   width: 80px;
   height: 80px;
   transition: none;
   right: 0px;
   top: 0px;
   transform: none;
}
.colorWhite{
   color: white!important;
}
@media only screen and (min-width: 320px) and (max-width : 1024px)  {
   .productImage {
      width: 90%;
      height: auto;
      border-radius: 20px;
   
   }
   .topsold {
      position: absolute;
      width: 70px;
      height: 70px;
      // border-radius: 50%;
      // background: red;
      right: 0px;
      top: 0px;
      overflow: hidden;
   }
   .btncallUs{
   

      line-height: 46px;
    background-color: #FF8439;
    width: fit-content;
    height: 46px;
    color: white !important;
    font-size: 12px;
    text-align: center;
    border-radius: 10px;
    opacity: 1;
      // width: 100%;
      
   }
}

