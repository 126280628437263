.bhheading {
   width: 100%;
   left: 0;
   top: 0;
   z-index: 2;
   position: absolute;
   background: transparent;
}

.logo {
   padding: 20px 0;
}

.logoWidth {
   width: 119px;
   height: 119px;
}

.contactColor {
   color: #222222;
   font-weight: normal;
   position: relative;
   // &::after{
   //    position: absolute;
   //    bottom: -10px;
   //    left: 0px;
   //    content: '';
   //    width: 100%;
   //    height: 2px;
   //    background-color: red;

   // }
}
.contactColorAcive {
   color: #222222;
   font-weight: normal;
   position: relative;
   &::after{
      position: absolute;
      bottom: -10px;
      left: 0px;
      content: '';
      width: 100%;
      height: 2px;
      background-color: #03c03c;

   }
}

.contactHeight {
   // height: 70px;
   margin: 0;
   padding: 0;
   list-style-type: none;
   // line-height: 70px;
   font-size: 16px;
   margin-top: -50px;
}
.contactHeight li{
   display: inline-block;
    margin: 8px;
    font-weight: 600;
    font-size: 14px;
    color: #222222;
    opacity: 1;
    cursor: pointer;
}
.contactHeight li:hover{
   color: #03C03C;
}
// .navaLogo{
//    // padding: 20px 0px;
// }
.contactHeight .listnavLogo{
   display: none;
}
@media only screen and (min-width: 320px) and (max-width : 768px) {
    .contactHeight {
        background-color: white;
        width: 75%;
        height: 100vh;
        top: 0;
        position: fixed;
        left: 0;
        z-index: -2;
        padding-top: 180px;
     //   display: none;
     }
   
     .contactHeight {
        // height: 70px;
        margin: 0;
        padding-top: 164px;
        list-style-type: none;
        // line-height: 70px;
        font-size: 16px;
        
     }
     .contactHeight li{
        display: block;
         padding: 8px;
         font-weight: 600;
         font-size: 16px;
         color: #222222;
         opacity: 1;
         cursor: pointer;
     }
   .logo {
      width: 100%;
      text-align: center;
   }
   .mobileLogo{
      text-align: center;
      // width: 75%;
   }
   // .contactHeight .listnavLogo{
   //    display: block;
   // }
   .navaLogo{
      justify-content: center;
   }

}
@media (max-width: 992px){

}
