.main{
   height: 209px;
   width: 100%;
   margin: 0;
   padding: 0;
   background-color: #222222;
   color: #FFFFFF;
}
.footermain{
   height: calc(209px - 50px);
   color: #FFFFFF;
   opacity: 0.5;
   
   text-align: center;

}
.footerCopyright{
   height: 50px;
   width: 100%;
   background-color: #2D2D2D;
   opacity: 50;
   color: #919191;
   text-align: center;
   line-height: 50px;
   font-size: 14px;
}
.list{
   margin: 0;
   padding: 0;
   // display: inline-block;
}
.list li{
   display: inline-block;
   padding: 0 24px;
    line-height:calc(209px - 50px); 
    font-size: 16px;
   
}
.colorGray{
   color: #FFFFFF!important;
   opacity: 1!important;
}
@media only screen and (min-width: 320px) and (max-width : 1024px)  {
   .list li{
      display: inline-block;
      padding: 0 20px;
      //  line-height:calc(209px - 50px); 
       font-size: 16px;
   }
   .main{
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      background-color: #222222;
      color: #FFFFFF;
   }
   .footermain{
      height: 140px;
      color: #FFFFFF;
      opacity: 0.5;
     
      text-align: center;
   
   }
}
