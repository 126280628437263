
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@600;700&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap'); */
@font-face {
  font-family: 'Montserrat', sans-serif;;
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@600;700&display=swap');
}
body{
  font-family: 'Montserrat', sans-serif!important;
}

.banner-wrapper{
  padding: 70px 0 70px;
  align-items: center;
}

.home-wrapper{
  position: relative;
}
.dashboard-filter{
  width: 30%!important;
  padding: 8px 16px;
}
.footer{
  padding-top: 70px;
}
a{
  color: initial !important;
  text-decoration: none!important;
}
.main{
  padding-top: 165px;
}
.imgColumn{
  width: 1000px;
}
.paddingCard{
  padding-top: 10px;
}
.formrequest{
  margin: 0!important;
  padding: 0!important;
}
.dashboard-filterFull{
  width: 42.5%!important;
  padding: 8px 16px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  background-color: #1AAE4E!important;
  color: white;
  border: none!important;
}
.nav-item{
  margin: 10px;
}
.nav-link{
  border: 1px solid #CBCBCB!important;
  border-radius: 8px!important;
}
@media only screen and (min-width: 320px) and (max-width : 768px)  {
  .banner-wrapper{
    padding: 140px 0 70px;
    align-items: center;
  }
  
  .dashboard-filter{
    width: 100%!important;
    text-align: center!important;
    margin: 0px!important;
    padding: 12px!important;
  }
  .dashboard-filterFull{
    width: 100%!important;
    text-align: center!important;
    margin: 0px!important;
    padding: 12px!important;
  }
  /* .paddingCard{
    margin: 0 auto; 
    float: none; 
    margin-bottom: 10px; 
  } */
  .cardbody{
    margin: 0px!important;
    padding: 0px 15px!important;
    /* margin: 0; */
    /* padding: 0px 15px!important; */
  }
  .formrequest{
    margin: 0px!important;
    padding: 0px!important;
  }
  .paddingCard{
    padding-top: 25px;
  }
  
}
.navbar-toggler .collapsed{
  overflow: auto!important;
  margin: 0!important;
  /* padding: 0!important; */
}


.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
  fill: white!important;
}
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  fill: white!important;
}

@media (max-width : 768px){
  /* .Dashboard_dflex__mtYxE{
    padding: 25px 0 10px 0;
  } */
  body:has(.navbar-collapse.collapse.show) {
    overflow: hidden;
  }
}
@media (min-width: 769px){

  .navbar-expand-lg .navbar-toggler {
      display: none;
  }
  .collapse:not(.show){
    display: block!important;
  }
  .navbar-collapse{
    flex-basis:auto!important;
    flex-grow: initial!important;
  }

}

