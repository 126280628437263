body {
   width: 100%;
}

.banner {
   width: 100%;
   background-color: #F2F2F2;
  
   // height: 807px;
   // position: absolute;
   // top: 0;
   // z-index: -1;

}
.dflex{
   display: flex;
}

.bannerText{
   text-align: center;
   // padding: 190px 0;
   width: 50%;
   // border: 1px solid black;
}

.bannerImages {
   position: relative;
   z-index: 0;
   width: 50%;
   display: none;
}

// .bannerImages::after {
//    content: "";
//    display: block;
//    position: absolute;
//    background-image: url('../../../public/imgs/orange.png');
//    background-position: top center;
//    background-repeat: no-repeat;
//    background-size: 307px;
//    top: 0;
//    width: 100%;
//    height: 100%;
//    right: 0px;

// }

.green {
   color: #03C03C;
   font-weight: 600;

   font-size: 20px;
   // padding-top: 40px;
}

.orange {
   color: #FF8439;
   font-weight: bold;
   
   font-size: 26px;
}

.black {
   color: #222222;
   font-weight: bold;
   font-size: 27px;
}



.paragrph {
   font-size: 18px;
   color: #848484;
   width: 505px;
}

.btn {
   padding: 10px 0px 0px 0px;
}

.btnown {
   background-color: #03C03C;
   color: #FFFFFF;
   // width: 176px;
   // height: 56px;
   padding: 20px 40px;
   border-width: 0;
   border-radius: 10px;
   font-size: 16px;
   text-align: center;
   font-weight: 600;
}


// .bannerImage::after{
//    width: 50px;
// }

.bannerImage {
   position: relative;
   // z-index: 10;
   // height: 573px;
   width: 100%;
   right: 0px;
   height: 300px;
   object-fit: contain;
  

}

.dashboardFilter {
   background-color: #222;
    border-radius: 10px;
    bottom: -50px;
    color: #fff;
    height: 100px;
    left: 0;
    padding: 15px;
    position: absolute;
    width: 100%;
    z-index: 1;
}


.allquiment {
   width: 250px;
   // height: 56px;
   background-color: #FFFFFF10
}

.cardHeading {
   font-size: 18px;
   font-weight: 600;
}
.cardtitle {
   font-size: 18px;
   font-weight: 600;
}
.cardtitle:hover{
   color: #03C03C;
   // font-weight: bold;
}

.fwnormal {
   font-weight: 400;
   font-size: 14px;
}

.fwBold {
   font-weight: 600;
   font-size: 14px;
}
.fwBolds{
   font-weight: 600;
   font-size: 32px;
}

.zoomWrapper {
   // border:1px dashed #B7B8BD;
   max-width: 400px;
   overflow: hidden;
   margin: 0px;
   cursor: pointer;
   position: relative;
   
   /* -- Prevent flicker -- */
   // -webkit-backface-visibility: hidden;
   // -webkit-transform: translate3d(0, 0, 0);
}

.zoomWrapper img {
   max-width: 100%;
   height: 100%;
   display: block;
   transition: all 1s ease;
   object-fit: cover;
}


.zoomWrapper img:hover {
   -webkit-transform: scale(1.25);
   -moz-transform: scale(1.25);
   -ms-transform: scale(1.25);
   -o-transform: scale(1.25);
   transform: scale(1.25);
}

.imgHover {
   position: relative;
}

.imageText {
   position: absolute;
}

.topsold {
   position: absolute;
   width: 80px;
   height: 80px;
   transition: none;
   right: 0px;
   top: 0px;
   transform: none;
}
.firstTextbox{
   width: 100%!important;
   height: 56px!important;
   border-radius: 10px!important;
   background-color: #ffffff10!important;
   margin: 0 10px!important;
   font-size: 14px;
   font-family: 'Montserrat'!important;
   padding: 0!important;
   border-radius: 10px!important;
}

.colorWhite{
   color: white!important;
  
}

.optionItem{
   color: #03C03C;
   width: 100%;
   margin: 0 15px!important;
   line-height: 30px!important;
}
.dashboard-filterbutton{
   width: 10%;
 }
 .dashboardfilterbutton{
   width: 15%;
   margin: 8px;
}
.btnfilter {
   background-color: #03C03C;
   color: #FFFFFF;
   width: 100%;
   height: 53px;
   margin: 0;
   padding: 0;
   // padding: 9px 30px;
   border-width: 0;
   border-radius: 10px;
   font-size: 16px;
   text-align: center;
}
.card{
   width: 100%;
   height: 338px;
}
.resetData{
   text-align: center;
   padding: 6px;
   text-decoration: underline;
   cursor: pointer;
   font-size: 10px;
}

@media only screen and (min-width: 1250px)  {
   .bannerImages{
      display: block;
      height: 300px;
   }
}
@media only screen and (min-width: 320px) and (max-width : 768px)  {
   .bannerText{
      // padding: 190px 0;
      width: 100%;
      // border: 1px solid black;
   }
   .bannerImages {
      position: relative;
      // z-index: 0;
      width: 100%;
    
    padding: 37px 0 0 0;

   }
   
   .orange {
      color: #FF8439;
      font-weight: bold;
      text-align: center;
      font-size: 28px;
   }
   .black {
      color: #222222;
      font-weight: bold;
      font-size: 28px;
   }
   .paragrph {
      font-size: 18px;
      color: #848484;
      width: 320px;
      text-align: center;
   }
   .dashboardFilter {
      background-color: #222222;
      height: 100%;
      width: 100%;
      position: relative;
      top: 0px;
      border-radius: 10px;
      color: white;
      padding: 0px;
      margin: 0;
   }
   .heading {
      text-transform: uppercase;
      // width: 320px;
      width: 100%;
      text-align: center;
   }
   .bannerImage {
      position: relative;
      // z-index: 10;
      // height: 573px;
      width: 100%;
      right: 0px;
   
   }
   .dflex{
      display: block;
   }
   .firstTextbox{
      width: 90%!important;
      height: 56px!important;
      border-radius: 10px!important;
      background-color: #ffffff10!important;
      // margin: 10px!important;
      font-size: 14px;
      font-family: 'Montserrat'!important;
      margin:0px!important;
      padding: 0px!important;
      border-radius: 10px!important;
      // padding: 10px 0px!important;
   }
   
   .btn {
      text-align: center;
      // padding: 30px 0px 0px 0px;
   }
   .fwBolds{
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      padding: 20px 0 0 0;
      margin: 0;
   }

  
   .dashboardfilterbutton{
      
          width: 100%!important;
    text-align: center!important;
    margin: 0px!important;
    padding: 12px!important;
   //  padding: 12px 32px;
   //  margin: 8px;
    }
    .btnfilter {
      background-color: #03C03C;
      color: #FFFFFF;
      width: 90%;
      height: 53px;
      margin: 0;
      padding: 0;
      // padding: 9px 30px;
      border-width: 0;
      border-radius: 10px;
      font-size: 16px;
      text-align: center;
   }
    .btnown {
      background-color: #03C03C;
      color: #FFFFFF;
      // width: 176px;
      // height: 56px;
      padding: 7px 14px;
      border-width: 0;
      border-radius: 10px;
      font-size: 16px;
      text-align: center;
   }
   .dashboardFilter{
      padding: 0px 0px;
     }
     
}
@media( max-width : 1024px) {
  .dashboardFilter{
   padding: 40px 0px;
  }
}